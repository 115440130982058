.section-misson {
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    background-color: #b6bff1;
    text-align: center;
    align-items: center;
    line-height: 2;

    h2 {
        font-size: 26pt;
        margin: 0 0 20px;
    }

    p {
        font-size: 18pt;
    }

    .mission-wrapper {
        margin: 0 150px 0;
    }

    @media screen and (max-width: 968px) {
        h2 {
            font-size: 16pt;
        }

        p {
            font-size: 12pt;
        }

        .mission-wrapper {
            margin: 0 50px 0;
        }
    }
}
