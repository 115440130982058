.projects {
    //padding-top: 25px;
    //padding-bottom: 100px;
    background-color: #cfd4f4;

    h2 {
        font-size: 32pt;
        margin-bottom: 10px;
    }

    .button {
        border: 2px solid black;
        /// background-color: rgb(231, 228, 255);
        background-color: black;
        color: white;
        padding: 5px 15px;
        text-decoration: none;
        border-radius: 10px;
    }

    .button:hover {
        background-color: #95a3ff;
        color: black;
    }
}

.projects-container {
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-left: 50px;
    display: flex;
}

.projects-container.upcoming {
    padding-bottom: 100px;
}

@media screen and (max-width: 900px) {
    .projects-container {
        align-items: center;
        flex-direction: column;

        &.upcoming {
            margin-top: 0;
        }

        .card-container {
            margin-bottom: 50px;
        }
    }
}
