.navbar {
    background: black;
    height: 76px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0;
}

.nav-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.nav-logo a {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    margin-left: 50px;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.nav-logo img {
    overflow: auto;
    margin: 15px 15px 0;
    width: 50px;
    height: auto;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 75vw;
    justify-content: flex-end;
    margin-right: 2rem;
    display: inline-flex;
}

.nav-menu-item {
    height: 80px;
}

.nav-menu-item a {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-menu-item a:hover {
    border-bottom: 5px solid #fff;
    transition: all 0.1s ease-out;
}

.fa-bars {
    color: #fff;
}

.menu-btn {
    display: none;
}

@media screen and (max-width: 968px) {
    .nav-menu {
        display: block;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.expanded {
        background: #242222;
        left: 0;
        opacity: 1;
        margin-top: -4px;
        transition: all 0.4s ease;
        z-index: 1;
    }

    .nav-menu-item a {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-menu-item a:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .nav-logo a {
        position: absolute;
        top: 0;
        left: 0;
    }

    .menu-btn {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
}
