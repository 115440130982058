.about {
    background-color: #cfd4f4;
    width: 100%;
    display: table;
    justify-content: center;

    /*
    .button-wrapper:hover {
        transform: scale(1.1);
    }

    .button {
        background-color: black;
        color: white;
        padding: 15px 25px;
        text-decoration: none;
    }*/

    .button {
        border: 2px solid black;
        /// background-color: rgb(231, 228, 255);
        background-color: black;
        color: white;
        padding: 15px 25px;
        text-decoration: none;
    }

    .button:hover {
        background-color: #95a3ff;
        color: black;
    }

    h2 {
        font-size: 26pt;
        margin-top: -10px;
        margin-bottom: 30px;
    }

    p {
        margin: 0 20px 50px;
        font-size: 18pt;
    }

    @media screen and (max-width: 968px) {
        h2 {
            font-size: 20pt;
            margin-top: -10px;
            margin-bottom: 20px;
        }

        p {
            margin: 0 20px 30px;
            font-size: 12pt;
        }

        .button {
            font-size: 10pt;
            padding: 10px 20px;
        }
    }

    @media screen and (max-width: 868px) {
        h2 {
            font-size: 14pt;
            margin-bottom: 16px;
        }

        p {
            margin: 0 20px 30px;
            font-size: 10pt;
        }
    }

    img {
        height: auto;
        width: 100%;
        padding: 0;
        background-color: #cfd4f4;
    }

    .about-wrapper-left {
        display: table-row;
        height: auto;
        text-align: center;

        .left {
            vertical-align: middle;
            width: 50%;
            display: table-cell;
            background: green;
            background-color: #cfd4f4;
        }

        .right {
            vertical-align: middle;
            display: table-cell;
            background-color: #cfd4f4;
        }
    }

    .about-wrapper-right {
        align-items: center;
        display: table-row;
        height: auto;
        text-align: center;
        vertical-align: middle;

        img {
            display: flex;
            height: auto;
            width: 100%;
            background-color: #cfd4f4;
        }

        .left {
            vertical-align: middle;
            display: table-cell;
            background-color: #cfd4f4;
        }

        .right {
            vertical-align: middle;
            width: 50%;
            display: table-cell;
            background-color: #cfd4f4;
        }
    }
}
