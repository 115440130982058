.no-page-content {
    display: table;
    height: 400px;
    background-color: rgb(156, 156, 156);
    width: 100%;
    text-align: center;
    line-height: 2.5;

    .no-page-content-wrapper {
        display: table-cell;
        vertical-align: middle;

        h2 {
            font-size: 26pt;
            margin: 0;
        }
    }

    p {
        font-size: 16pt;
    }

    a {
        color: rgb(40, 40, 211);
        text-decoration: none;
    }

    a:hover {
        color: blue;
    }
}
