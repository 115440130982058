.projects-home {
    background-color: #cfd4f4;
    padding-bottom: 100px;
    margin-top: -70px;
    padding-bottom: 100px;
}
.projects-home h2 {
    margin-bottom: 10px;
    font-size: 32pt;
}
.projects-home .projects-container {
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding: 0;
    display: flex;
}
.projects-home .projects-container .button-wrapper {
    margin-top: 25px;
}
.projects-home .projects-container .button {
    border: 2px solid black;
    background-color: rgb(231, 228, 255);
    color: black;
}
.projects-home .projects-container .button:hover {
    background-color: black;
    color: #cfd4f4;
}

@media screen and (max-width: 1248px) {
    .projects-container {
        align-items: center;
        flex-direction: column;
    }
    .projects-container.upcoming {
        margin-top: 0;
    }
    .projects-container .card-container {
        margin-bottom: 50px;
    }
} /*# sourceMappingURL=Home.css.map */
