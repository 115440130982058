* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
@media screen and (max-width: 648px) {
    body {
        display: flex;
    }
}

h1,
h2,
h3 {
    font-family: 'Playfair Display', serif;
}

p,
a,
small,
label {
    font-family: 'Poppins', sans-serif;
}
