video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-wrapper-home {
    height: 250px;
    width: 100%;
    display: table;
    align-items: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.55);
}
.hero-wrapper-home h1,
.hero-wrapper-home h2,
.hero-wrapper-home a,
.hero-wrapper-home p {
    color: white;
}
.hero-wrapper-home h2 {
    font-size: 20pt;
}
.hero-wrapper-home h1 {
    margin-bottom: -125px;
}
.hero-wrapper-home a {
    text-decoration: none;
    margin-right: 20px;
}
.hero-wrapper-home a:hover {
    transform: scale(1.1);
}
.hero-wrapper-home .button {
    background-color: black;
    color: #cfd4f4;
    padding: 10px 20px;
    text-decoration: none;
}
.hero-wrapper-home .button-wrapper:hover {
    transform: scale(1.1);
}

.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
    -o-object-fit: contain;
    object-fit: contain;
} /*# sourceMappingURL=Hero.css.map */
