.card-container {
    width: 350px;
    height: 400px;
    overflow: hidden;
    transition: 0.4s;
    animation: ease-in;
    border: solid;
    margin: 0 50px 0;
    text-align: center;

    .img-container {
        overflow: hidden;
        height: 200px;

        img {
            height: 200px;
            width: 100%;
        }
    }

    .button {
        background-color: black;
        color: #cfd4f4;
        padding: 10px 20px;
        text-decoration: none;
    }

    .card-title {
        margin: 0;
        margin-top: 7px;
        font-size: 16pt;
        margin-bottom: 0.5rem;
    }

    .card-text {
        margin: 1rem;
        margin-top: 0.5rem;
    }
}

.card-container:hover {
    transform: scale(1.06);
}
