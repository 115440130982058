.apply {
    background-color: #cfd4f4;
}

.apply-disclaimer {
    background-color: #cfd4f4;
    height: 20vh;
    line-height: 3;
    text-align: center;
}
.apply-disclaimer a {
    text-decoration: none;
    color: blue;
}
.apply-disclaimer h2 {
    font-size: 18pt;
}
@media screen and (max-width: 960px) {
    .apply-disclaimer h2 {
        margin: 0 50px 0;
    }
}
@media screen and (max-width: 760px) {
    .apply-disclaimer h2 {
        padding-top: 20px;
        font-size: 13pt;
        line-height: 2.8;
    }
} /*# sourceMappingURL=Apply.css.map */
