.main {
    height: 50vh;
    padding-bottom: 50px;
    width: 100%;
    display: flex;
    background-color: #cfd4f4;
    align-items: center;
    justify-content: center;
    line-height: 2;
    margin: auto;
    vertical-align: middle;

    h2 {
        text-align: center;
        vertical-align: middle;
        font-size: 32pt;
        margin-bottom: 30px;
    }

    .contacts-wrapper {
        a {
            color: blue;
            text-decoration: none;
        }

        svg {
            color: black;

            &:hover {
                color: rgb(21, 23, 116);
            }
        }

        a:hover {
            text-decoration: underline;
        }
    }

    p {
        font-size: 18pt;
    }
}
