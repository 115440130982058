video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-wrapper-home {
    height: 250px;
    width: 100%;
    display: table;
    align-items: center;
    text-align: center;
    background-color: rgb(0, 0, 0, 0.55);

    h1,
    h2,
    a,
    p {
        color: white;
    }

    h2 {
        font-size: 20pt;
    }

    h1 {
        margin-bottom: -125px;
    }

    a {
        text-decoration: none;
        margin-right: 20px;
    }

    a:hover {
        transform: scale(1.1);
    }

    .button {
        background-color: black;
        color: #cfd4f4;
        padding: 10px 20px;
        text-decoration: none;
    }

    .button-wrapper:hover {
        transform: scale(1.1);
    }
}

.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
    object-fit: contain;
}
