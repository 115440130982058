.gallery {
    background-color: #cfd4f4;
}

.gallery-disclaimer {
    background-color: #cfd4f4;
    height: 20vh;
    line-height: 3;
    text-align: center;

    a {
        text-decoration: none;
        color: blue;
    }

    h2 {
        font-size: 18pt;

        @media screen and (max-width: 960px) {
            margin: 0 50px 0;
        }

        @media screen and (max-width: 760px) {
            padding-top: 20px;
            font-size: 13pt;
            line-height: 2.8;
        }
    }
}
