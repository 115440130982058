.main {
    height: 50vh;
    padding-bottom: 50px;
    width: 100%;
    display: flex;
    background-color: #cfd4f4;
    align-items: center;
    justify-content: center;
    line-height: 2;
    margin: auto;
    vertical-align: middle;
}
.main h2 {
    text-align: center;
    vertical-align: middle;
    font-size: 32pt;
    margin-bottom: 30px;
}
.main .contacts-wrapper a {
    color: blue;
    text-decoration: none;
}
.main .contacts-wrapper svg {
    color: black;
}
.main .contacts-wrapper svg:hover {
    color: rgb(21, 23, 116);
}
.main .contacts-wrapper a:hover {
    text-decoration: underline;
}
.main p {
    font-size: 18pt;
} /*# sourceMappingURL=Contacts.css.map */
