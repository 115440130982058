.section-misson {
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    background-color: #b6bff1;
    text-align: center;
    align-items: center;
    line-height: 2;
}
.section-misson h2 {
    font-size: 26pt;
    margin: 0 0 20px;
}
.section-misson p {
    font-size: 18pt;
}
.section-misson .mission-wrapper {
    margin: 0 150px 0;
}
@media screen and (max-width: 968px) {
    .section-misson h2 {
        font-size: 16pt;
    }
    .section-misson p {
        font-size: 12pt;
    }
    .section-misson .mission-wrapper {
        margin: 0 50px 0;
    }
} /*# sourceMappingURL=MissionStatement.css.map */
