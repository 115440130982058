.socials-button-group-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;

    svg {
        width: 40px;
        height: 40px;
    }
}

.social-button-icon {
    color: #aaaaaa;
    transition: all 0.15s ease-in;
    text-decoration: none;

    &:hover {
        color: #7989e8;
    }
}
