.section-purpose {
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    background-color: #cfd4f4;
    text-align: center;
    align-items: center;
    line-height: 2;
}
.section-purpose h2 {
    padding-top: 0;
    font-size: 32pt;
    margin: 0 0 20px;
}
.section-purpose p {
    font-size: 18pt;
}
.section-purpose .purpose-wrapper {
    margin: 0 150px 0;
}
@media screen and (max-width: 968px) {
    .section-purpose p {
        font-size: 16pt;
    }
    .section-purpose .purpose-wrapper {
        margin: 0 50px 0;
    }
} /*# sourceMappingURL=PurposeStatement.css.map */
